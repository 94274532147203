.text-area {
  .input__label {
    color: var(--color-text-90-light) !important;
  }
  .input__text-field:hover{
    border-color: var(--color-esign-primary-light);
  } 
}
.text-area-field{
    font-family: Poppins ;
    font-size: var(--font-size-2);
  }


