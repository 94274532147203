.signing-screen {
  &__footer {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: calc(var(--margin) * 0.5);
    padding: 0 var(--padding);
    border-top: 1px solid var(--color-input-border-light);
    border-bottom: 1px solid var(--color-input-border-light); 
    @media screen and (max-width: 480px) {
      position: fixed;
      bottom: 0;
    }
    .button__filled--primary{
      &:hover {
        background: #2347A9;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
      }
    } 
    .button__filled--secondary{
      &:hover {
        background:#D6DCEB;
        color: #363A45;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
      }
    }
  }
}
.finish-sign {
  &__btn {
    height: 52px;
    width: 108px;
  }
}