  .collapsible {
    width: 100%;
    display: flex;
    flex-direction: column;  
    cursor: pointer;
    &__header {
      width: 100%;
      border-bottom: 1px solid var(--color-input-border-light);
      padding-top: 8px;
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        font-size: 20px;
      }
      span {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &__children {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding-top: 8px;
      background-color: rgb(243, 243, 246);
      padding-bottom: 16px;
    }
  }
