.message-banner {
	width: 100%;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 12px 16px;
	gap: 8px;
	&--info {
        border: 1px solid var(--color-esign-primary-light);
		background-color: rgba(60, 101, 214, 0.06);
	}
	&--error {
        border: 1px solid var(--color-danger-dark);
		background-color: rgba(245, 69, 69, 0.12);
	}
	&--warning {
		border: 1px solid var(--color-accent-light);
		background-color: rgba(239, 182, 67, 0.06);
	}
	&__icon {
		font-size: 20px;
		&--info {
            color: var(--color-esign-primary-light);
		}
		&--error {
            color: var(--color-danger-dark);
		}
		&--warning {
			color: var(--color-accent-light);
		}
	}
	&__message-wrapper {
		font-size: 12px;
		line-height: 16px;
		display: flex;
		align-items: center;
		gap: 4px;
		color: rgba(70, 76, 92, 1);
		padding: 4px 0px;
	}
	&__note {
		font-weight: 600;
		width: fit-content;
		white-space: nowrap;
	}
	&__message {
		font-weight: 500;
	}
}
