.esign-gif-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	&__position{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 16px;
	}
}

.loader {
	border-radius: 50%;
	animation: spin 2s linear infinite;
}
.loader-blue {
	border: 3px solid var(--color-esign-primary-light);
	border-top: 3px solid var(--color-bg-100-light);
}
.loader-white {
	border: 3px solid var(--color-bg-100-light);
	border-top: 3px solid var(--color-esign-primary-light);
}
.loader-pink {
	border: 3px solid var(--color-bg-100-light);
	border-top: 3px solid var(--color-danger-dark);
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
