.select-options {
  align-items: center;
  display: flex;
  font-size: 14px;
  border-radius: 4px;
  height: 20px;
  max-height: 20px;
  span {
    display: block;
    border-radius: 8px;
    content: " ";
    margin-right: 8px;
    width: 8px;
    height: 8px;
  }
  p {
    width: 142px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: unset;
  }
}

.common-field {
  display: flex;
  column-gap: 8px;
  color: #6c707a;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  height: 50px;
  border-top: 1px solid #d7dff5;

  &:hover {
    .common-field__icon {
      background-color: #4a5060;
    }

    .common-field__label { 
      color: #373a3f;
    }
  }

  &__label {
    height: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6c707a;
    margin: unset;
    padding: unset;
  }

  &__icon {
    display: block;
    border-radius: 8px;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    background-color: #6c707a;
  }
}

@media screen and (max-width: 860px) {
  .select-options {
    p {
      width: 90px;
    }
  }
}
