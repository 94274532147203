.config-doc-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: calc(var(--margin) * 0.5);
  width: 100%;
  height: inherit;
  padding: 10px 15px;
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

  }

  &__time-frame {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: calc(var(--margin) * 0.75);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    color: var(--color-text-60-light);
  }
  &__date {
    position: relative;
    white-space: nowrap;
  }
  &__time {
    white-space: nowrap;
  }
  &__date::after {
    content: "";
    width: 1px;
    background-color: var(--color-input-border-light);
    position: absolute;
    right: -6px;
    top: 0;
    bottom: 0;
  }
  &-toolkit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(var(--margin) * 0.5);
    .icon-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: calc((var(--padding) * 0.5)) var(--padding);
      gap: calc(var(--margin) * 0.5);
      width: 50px;
      height: 36px;
      background: #ffffff;
      border: 1px solid #d7dff5;
      border-radius: 4px;
    }
  }
  &-button-container {
    .icon-btn {
      border-radius: calc(var(--margin) * 0.25);
    }

    :hover {
      color: var(--color-blue);
      transition: all 0.2s ease-in-out;
      background: var(--color-input-border-light) !important;
    }
  }
}
