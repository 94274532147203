.Esign-video {
	clear: both;
	display: flex;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	margin-bottom: 20px;
	margin-top: calc(var(--margin) * -4);
}

.Esign-output_canvas {
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	position: absolute;
	left: 0;
	top: 0;
	overflow: hidden;
	margin-top: calc(var(--margin) * -4);
	pointer-events: none;
}

.Esign-facial {
	&__wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
	&__info-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 600;
		font-size: var(--font-size-6);
		line-height: var(--line-height-6);
	}
	&__loading-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: calc(var(--padding) * 1.5);
	}
	&__loading-heading {
		font-weight: 600;
		font-size: calc(var(--font-size-4) * 1.25);
		line-height: calc(var(--line-height-4) * 1);
		text-align: center;
		padding: var(--padding);
	}
	&__loading-instructions {
		font-weight: 400;
		font-size: calc(var(--font-size-4) * 0.75);
		line-height: calc(var(--line-height-4) * 1);
		align-items: center;
		color: #5d657a;
		margin-bottom: 32px;
		text-align: center;
	}
	&__success-gif {
		width: 80px;
		height: 80px;
	}
	&__info-message {
		font-weight: 600;
		font-size: calc(var(--font-size-4) * 1.1);
		line-height: calc(var(--line-height-6) * 0.5);
		font-family: 'Poppins';
		font-style: normal;
		padding: calc(var(--padding) * 1.5) 0;
		text-align: center;
		height: 80px;
	}
}

@media only screen and (max-width: 600px) {
	.Esign-facial__info-message {
		font-size: 16px;
	}
}
