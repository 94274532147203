@import url('https://fonts.googleapis.com/css2?family=Delicious+Handrawn&family=Fasthand&family=Freehand&family=Just+Another+Hand&family=Lato&family=Open+Sans&family=Oswald&family=Patrick+Hand&family=Poppins:wght@400;500;600;700&family=Raleway&family=Roboto&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Allura&family=Caveat&family=Dancing+Script&family=Great+Vibes&family=Italianno&family=Pacifico&family=Sacramento&family=Satisfy&family=WindSong&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Great+Vibes&family=Homemade+Apple&family=Inspiration&family=Kristi&family=Monsieur+La+Doulaise&family=Reenie+Beanie&family=Sacramento&family=Zeyada&display=swap');
@import "variables/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-4);
}

input[type="date"] {
  position: relative;
}

input[type="date"]:before {
  content: attr(placeholder);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  color: #747171a6;
  pointer-events: none;
  line-height: 1.5;
  padding: 0 0.5rem;
}

input[type="date"]:focus:before,
input[type="date"]:not([value=""]):before,
input[type="file"]
{
  display: none;
}
div[contenteditable="true"]::before {
  content: attr(placeholder); // this will take the placeholder attribute of the div element and use as content
  color: #747171a6;
}
textarea::placeholder {
  color: #747171a6;
  opacity: 1; /* Firefox */
  font-size: attr(font-size);
}