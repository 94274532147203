.server-disconnected {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(var(--margin) * 0.75);
  &__logo {
    width: 200px;
    height: 186px;
    object-fit: contain;
  }

  &__message {
    display: flex;
    flex-direction: column;
    gap: calc(var(--margin) * 0.25);
    align-items: center;
    max-width: 350px;
  }
  &__heading {
    font-size: 20px;
    font-weight: 600;
    color: var(--color-black);
    margin-bottom: var(--margin);
  }
  &__description {
    color: #6c7489;
    font-size: var(--font-size-3);
    font-weight: 500;
    text-align: center;
    margin-bottom: var(--margin);
  }

  &__btn {
    width: 120px;
    text-align: center;
    font-size: var(--font-size-3);
    border: none;
    color: var(--color-bg-100-light);
    cursor: pointer;
    .esign-button-label-txt{
      font-size: 15px;
      font-weight: 600;
    }
  }
}
