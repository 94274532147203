.signing-order-view {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: #3c65d6;
  background-color: white;
  border: none;
}

.signing-order-modal {
  width: 550px;
  .Modal__wrapper {
    padding: unset;
    border-radius: calc(var(--margin) * 0.5);
  }
}
.esign-Modal__wrapper {
  background-color: #fff;
}

.sign-order-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .biomatric-container{
    display: flex;
    gap: 16px;
  }
}