.no-recipient {
  &-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
    img {
      width: 120px;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #464c5c;
    }
  }
}
