.user-kyc {
	&__modal {
		height: 100%;
		width: 100%;
		max-width: 500px;
        max-height: 80vh;
        margin: auto;
		.esign-Modal__wrapper {
			width: inherit;
			height: inherit;
			border-radius: calc(var(--border-radius) * 0.5);
			padding: calc(var(--padding) * 0.25);
		}

	}
    &__loader {
        width: 100%;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__iframe {
        width: 100%;
        height: 100%;
        border: 0px;
    }
}
