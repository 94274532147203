.warning-modal {
    width: 550px;
    .Modal__wrapper {
      padding: unset;
      border-radius: calc(var(--margin) * 0.5);
    }
    &__body {
      max-height: 436px;
      overflow-y: auto;
      padding: 20px;
    }
    &__header {
      font-size: var(--font-size-5);
      font-weight: 500;
      padding: var(--padding);
      border-bottom: 1px solid var(--color-input-border-light);
      font-size: var(--font-size-4);
      line-height: var(--line-height-6);
      color: var(--color-text-90-light);
      border-bottom: 1px solid var(--color-input-border-light);
    }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: var(--padding);
        gap: calc(var(--margin) * 0.5);
        border-top: 1px solid var(--color-input-border-light);
        .button__filled--primary {
            &:hover {
            background: #2347a9;
            transition: all 0.2s ease-in-out;
            box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
            }
        }
    }
}