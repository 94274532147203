.configuration {
   

    &__heading {
        display: block;
        font-size: 24px;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: var(--font-size-6);
        line-height: var(--line-height-4);
        color: var(--color-text-90-light)
    }

    p {
        font-size: 14px;
        color: #6c757d;
        margin-bottom: 10px;
    }

    .checkbox-options,
    .appearance {
        // margin-bottom: 10px;

        &__heading {
            margin-bottom: 5px;
            color: var(--color-text-90-light);
            font-size: var(--font-size-3);
            display: block;
            text-transform: capitalize;
            font-weight: 500;
        }

        label {
            display: block;
            margin: 4px;
            font-size: 13px;
            cursor: pointer;
            display: flex;
            font-weight: 500;
            align-items: center;
            margin-bottom: 0px;
            input[type='radio'] {
                margin-right: 10px;
            }
        }
    }

    &__option-container{
        padding: 4px;
        border-radius: 4px;
        border: 1px solid #D9E0F2;
        background: #F3F6FD;
        margin-bottom: 10px;
    }

    .apply-setting {
        
        font-size: 13px !important;
        margin: 0px !important;
        margin-top: 10px !important;
        font-weight: 500;
        gap: 5px !important;
    }
}


// padding: 8px;
//     border-radius: 4px;
//     border: 1px solid #D9E0F2;
//     background: #F3F6FD;