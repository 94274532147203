.font-family-dropdown {
  display: grid;
  gap: calc(var(--margin) * 0.25);
  &__label {
    font-weight: 500;
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    color: var(--color-text-90-light);
  }
}
