.option-wrapper {
  position: relative;
  &__option-box {
    position: absolute;
    min-width: 92px;
    opacity: 22;
    background: var(--color-bg-100-light);
    border-radius: calc(var(--margin) * 0.25);
    min-height: 180;
    box-shadow: var(--color-input-border-light) 0px 8px 24px;
    border: 1px solid var(--color-input-border-light);
    display: flex;
    flex-direction: column;
    z-index: 99;
    left: -82px;
    top: 20px;
  }
  &__option-box-top {
    position: absolute;
    min-width: 92px;
    opacity: 22;
    background: var(--color-bg-100-light);
    border-radius: calc(var(--margin) * 0.25);
    min-height: 180;
    box-shadow: var(--color-input-border-light) 0px 8px 24px;
    border: 1px solid var(--color-input-border-light);
    display: flex;
    flex-direction: column;
    z-index: 99;
    left: -82px;
    top: -84px;
  }
  &__menu-item {
    padding: 4px 8px;
    cursor: pointer;
    font-size: var(--font-size-3);
    align-items: center;
    z-index: 1;
    font-weight: 600;
    line-height: var(--line-height-5);
    color: var(--color-text-70-light);
    border: none;
    background-color: unset;
    display: flex;
    align-items: center;
    gap: calc(var(--margin) * 0.75);
    height: 40px;
  }
  &__menu-item:hover {
    background-color: var(--color-bg-90-light);
    border-radius: 2px;
    transition: all 0.3s;
    cursor: pointer;
  }
}

.menu-tab {
  &__search {
    padding: calc(var(--padding) * 0.75) var(--padding);
    .input__group {
      height: 38px !important;
    }
    .input__text-field {
      &:hover {
        border: 1px solid var(--color-esign-primary-light);
        transition: all 0.2s ease-in-out;
        box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
      }
    }
  }
  &__select-recipient {
    padding: calc(var(--padding) * 0.5) var(--padding) 0;
    display: flex;
    flex-direction: column;
  }

  &__select-recipient-label {
    font-size: var(--font-size-3);
    &:focus-visible {
      outline: none;
  }
  }

  &__container {
    display: flex;
    gap: calc(var(--margin) * 0.5);
    padding: 0 var(--padding) calc(var(--padding) * 0.5);
    height: 100%;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    gap: calc(var(--margin) * 0.5);
  }
  &__button {
    display: grid;
    place-items: center;
    width: 48px;
    height: 48px;
    background: var(--color-input-border-light);
    border-radius: 6px;
    text-align: center;
  }

  &__wraper {
    min-width: 186px;
    height: 404px;
    overflow: auto;
    padding-bottom: calc(var(--padding) * 0.5);
  }

  &__btns {
    display: grid;
    place-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid var(--color-input-border-light) !important;
    background: var(--color-input-background) !important;
    border-radius: 6px;
    &:hover {
      border: 1px solid var(--color-esign-primary-light) !important;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
    }
    &--active {
      background-color: var(--color-esign-primary-light) !important;
      color: var(--color-bg-100-light);
    }
  }

  &__fields {
    width: 100%;
    display: flex;
  }

  &__sideBar-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: calc(var(--margin) * 0.5);
  }
  &__box-container {
    border: 1px solid var(--color-input-border-light);
    z-index: 9;
  }
  &__box {
    width: 100%;
    background: rgba(60, 101, 214, 0.06);
    padding: calc(var(--padding) * 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: grab;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-2);
    color: var(--color-text-80-light);
    word-break: break-word;
    &:hover {
      transition: all 0.2s ease-in-out;
    }
    i {
      color: var(--color-text-60-light);
      font-size: var(--font-size-4);
      font-weight: 600;
    }
    &--disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  &__box-safari{
    background: rgb(239,236,230);
  }
  &__box-wrapper {
    display: flex;
    align-items: center;
    gap: calc(var(--margin) * 0.75);
    text-transform: capitalize;
  }
  &__custom {
    &-heading-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-heading {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      padding-right: var(--padding);

      button {
        display: flex;
        height: 25px;
        width: 25px;
        background-color: rgba(60, 101, 214, 0.06);
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }
      .ri-add-line {
        color: #3c65d6;
      }
    }
    &-menu-hover-container {
     
      .ri-ball-pen-line {
        color: var(--color-dodger-blue);
        &:hover {
          color: var(--color-primary-dark-blue);
        }
      }
      .ri-delete-bin-5-fill {
        color: var(--color-delete);
        &:hover {
          color: var(--color-danger-dark);
        }
      }
    }
    &-tab-modal {
      .Modal {
        &__closeButton {
          width: 24px;
          height: 24px;
          border-radius: calc(var(border-radius) * 1.5);
          margin-top: calc(var(--margin) * 1.5);
          i {
            font-size: var(--font-size-3);
          }
        }
      }
      &_body {
        display: flex;
        flex-direction: column;
        gap: var(--margin);
        margin-top: var(--margin);
        .button__filled--primary {
          align-self: flex-end;
        }
      }
    }
  }

  &__remove-tab-item {
    color: var(--color-danger-dark);
  }
}

.ri-lg {
  line-height: unset !important;
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: calc(var(--margin) * 0.75);
  width: 100%;
  height: 100%;
  &__heading {
    width: 100%;
    text-align: left;
    color: var(--color-text-90-light);
    font-weight: 600;
    font-size: var(--font-size-4);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__wrap {
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      //-webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.3);
      background-color: var(--color-gray);
    }
  }
  &__envelope-items {
    display: flex;
    flex-direction: column;
    gap: calc(var(--margin) * 0.25);
    height: calc(100vh - 322px);
    width: 100%;
    .icon-btn {
      height: unset;
    }
  }

  &__searchable{
    height: calc(80vh - 300px) !important;
  }
  &__notSearchable{
    height: calc(80vh - 270px) !important;
  }


  &__template-items {
    display: flex;
    flex-direction: column;
    gap: calc(var(--margin) * 0.25);
    height: calc(100vh - 320px);    
    width: 100%;
    padding-right: 12px !important;
    .icon-btn {
      height: unset;
    }
  }
  &__template-items-overlay {
    display: flex;
    flex-direction: column;
    gap: calc(var(--margin) * 0.25);
    height: calc(100vh - 322px);
    width: 179px;
    .icon-btn {
      height: unset;
    }
  }
  &__empty-state {
    display: flex;
    justify-content: center;
    margin-top: calc(var(--margin) * 3);
    width: 100%;
    img {
      width: 60%;
    }
  }
}

@media screen and (max-width: 860px) {
  .menu-items {
    &__wrap {
      width: 132px;
      overflow-y: auto;
    }
    &__envelope-items {
      width: 120px;
      i {
        display: none;
      }
    }
    &__template-items {
      width: 120px;
      i {
        display: none;
      }
    }
    &__heading {
      width: 120px;
    }
  }
  .menu-tab {
    &__custom {
      &-heading {
        padding-right: unset;
      }
      &-heading-text {
        width: 88px;
      }
    }
  }
  .search-items__data {
    .menu-items__envelope-items {
      width: 100%;
    }
  }
}

//Delete Node Scss
.left-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  &__remove-node {
    height: 100%;
    width: 100%;
    padding: var(--padding);
    .delete-node {
      border: 2px solid var(--color-danger-dark);
      background-color: rgba(245, 83, 83, 0.04);
      border-style: dashed;
      width: 100%;
      display: grid;
      height: 100%;
      place-items: center;
      i {
        color: var(--color-rejected);
      }
    }
  }
  &-drop-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.search-items {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    padding: 0 var(--padding) calc(var(--padding) * 0.5);
    color: var(--color-text-90-light);
    font-weight: 600;
    font-size: var(--font-size-5);
    display: flex;
    align-items: center;
    gap: calc(var(--margin) * 0.5);
  }
  &__header-text {
    width: min-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__header-badge {
    background-color: var(--color-esign-primary-light);
    color: #fff;
    font-size: var(--font-size-2);
    padding: calc(var(--padding) * 0.25) calc(var(--padding) * 0.5);
    border-radius: calc(var(--border-radius) * 1.25);
  }
  &__data {
    min-height: unset;
    padding: 0 var(--padding) calc(var(--padding) * 0.5);
    display: grid;
    gap: var(--margin);
    max-height: calc(100vh - 328px);
    height: 100%;
    overflow-y: auto;
    .menu-items__wrap {
      width: 100%;
    }
    .menu-items__items {
      width: 100%;
      height: unset;
    }
    .menu-items__envelope-items {
      height: unset !important;
    }
    .isquestionnaire{
      height: calc(100vh - 350px) !important;
    }
    .menu-items__template-items{
      height: unset !important;
    }
  }

.isSearchItemWithQuestionaire{
  max-height: calc(100vh - 335px);
}
  &__empty-state {
    display: flex;
    justify-content: center;
    margin-top: calc(var(--margin) * 3);
    width: 100%;
    img {
      width: 40%;
    }
  }
}

.create-custom-tab {
  &__modal {
    width: 420px;
    display: flex;
    flex-direction: column;

    .esign-Modal__wrapper {
      margin: unset;
      padding: unset !important;
      border-radius: 8px;
      background: #ffffff;
    }
  }

  &__header {
    width: 100%;
    border-bottom: 1px solid var(--color-border-90-light);
    padding: 16px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 16px;
    .input__group {
      height: 38px !important;
    }
  }
  &__footer {
    margin-top: calc(var(--margin) * 1);
    padding: 8px;
    border-top: 1px solid var(--color-border-90-light);
    display: flex;
    justify-content: flex-end;
  }
}
.isquestionnaire{
  height: calc(100vh - 350px) !important;
}