.footer-btn {
  display: flex;
  gap: calc(var(--margin) * 0.5);
  justify-content: flex-end;
  align-items: center;
  padding-right: calc(var(--padding) * 1.5);

  .button__filled--primary {
    &:hover {
      background: #2347A9;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
    }
  }
  .button__filled--secondary{
    &:hover {
      background: var(--color-light-grey);
      transition: all 0.2s ease-in-out;
      box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
    }
  }
}
