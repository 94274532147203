.create-sign-modal {
	background-color: var(--color-bg-100-light) !important;
	width: 100%;
	max-width: 731px;
	.esign-Modal__wrapper {
		padding: unset;
		border-radius: calc(var(--border-radius) * 0.5);
		display: flex;
		flex-direction: column;
	}
}

.create-sign-header {
	width: 100%;
	height: 64px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--color-input-border-light);
	padding: 0 calc(var(--padding) * 1.5);
	font-style: normal;
	font-weight: 600;
	font-size: var(--font-size-4);
	line-height: var(--line-height-3);
	color: var(--color-text-80-light);
}

.create-sign {
	max-height: calc(100vh - 148px) !important;
	overflow: auto !important;
	padding: calc(var(--padding) * 1.5);
	display: flex;
	flex-direction: column;
	gap: calc(var(--margin) * 0.5);
	&__label {
		font-style: normal;
		font-weight: 500;
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		color: var(--color-text-60-light);
	}
	&__step-wrapper {
		height: calc(100% - 126px);
		width: 100%;
		display: flex;
	}
	&__consent {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: calc(var(--margin) * 0.5);
	}
	&__consent-description {
		font-style: normal;
		text-align: left;
		font-weight: 500;
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		color: var(--color-text-60-light);
	}
	&__loader-light {
		height: 40px;
		border-radius: calc(var(--border-radius) * 0.5);
		display: block;
		margin-bottom: 8px;
		background: linear-gradient(
				to right,
				rgba(255, 255, 255, 0),
				rgba(255, 255, 255, 0.2) 50%,
				rgba(255, 255, 255, 0) 80%
			),
			#adb0b8;
		background-repeat: repeat-y;
		background-size: 20px 50px;
		background-position: 0 0;
		animation: shine 2s infinite;
	}
}
@keyframes shine {
	to {
		background-position:
			100% 0,
			/* move highlight to right */ 0 0;
	}
	100% {
		background-position: 100% 0;
	}
}

.sign-stepper {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
	&__header {
		height: 52px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--color-input-border-light);
	}
	&__header-item {
		padding: var(--padding);
		font-style: normal;
		font-weight: 500;
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		display: flex;
		align-items: center;
		color: var(--color-text-50-light);
		cursor: pointer;
		position: relative;
		&--active {
			color: var(--color-esign-primary-light);
			&::after {
				height: 2px;
				display: block;
				width: 100%;
				background: var(--color-esign-primary-light);
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				content: '';
			}
		}
	}
	&__body {
		padding-top: calc(var(--padding) * 0.5);
		height: calc(100% - 52px);
	}
}

.upload-sign {
	display: flex;
	cursor: pointer;
	height: inherit;
	width: 100%;
	&__wrapper {
		height: 100%;
		width: 100%;
	}
	&__sign-box {
		padding: 24px;
		width: 100%;
		height: inherit;
		border: 1px dashed var(--color-border-90-light);
		border-radius: calc(var(--border-radius) * 0.25);
		background-color: var(--color-bg-90-light);
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		gap: calc(var(--margin) * 0.25);
	}
	&__sign-img {
		width: 100%;
		height: inherit;
		border: 1px dashed var(--color-border-90-light);
		border-radius: calc(var(--border-radius) * 0.5);
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		gap: calc(var(--margin) * 0.5);
	}
	&__render-img {
		object-fit: contain;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		height: 116px;
		max-width: 280px;
	}
	&__icon {
		font-size: 52px;
		color: var(--color-esign-primary-light);
		margin-bottom: calc(var(--margin) * 0.25);
	}
	&__upload-text {
		font-style: normal;
		font-weight: 600;
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		color: var(--color-esign-primary-light);
	}
	&__upload-description {
		font-weight: 500;
		font-size: var(--font-size-2);
		line-height: var(--line-height-2);
		color: var(--color-text-50-light);
	}

	&__input {
		display: none;
	}
}


.draw-sign {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: calc(var(--margin) * 0.5);

	&__format-color {
		position: relative;
	}
	&__format-color-btn {
		display: flex;
		align-items: center;
		gap: calc(var(--margin) * 0.5);
		color: var(--color-text-60-light);
		cursor: pointer;
		font-size: var(--font-size-3);
		line-height: var(--line-height-5);
		font-weight: 500;
	}
	&__active-color {
		width: 24px;
		height: 24px;
		border-radius: calc(var(--margin) * 0.25);
		border: 2px solid var(--color-input-border-light);
	}
	&__format-color-modal {
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 10;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	&__header-label {
		font-style: normal;
		font-weight: 500;
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		color: var(--color-text-60-light);
	}
	&__clear-btn {
		font-style: normal;
		font-weight: 500;
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		color: var(--color-esign-primary-light);
		cursor: pointer;
	}
	&__sign-pad {
		width: 100%;
		height: 100%;
		background-color: var(--color-input-background);
		border: 1px solid var(--color-input-border-light);
		border-radius: calc(var(--border-radius) * 0.25);
		min-height: 150px;
	}
}

.create-sign-name {
	display: flex;
	align-items: baseline;
	width: 100%;
	gap: calc(var(--margin) * 0.75);
	&__full-name {
		width: 100%;
	}
	&__initials {
		max-width: 240px;
	}
}

.create-sign-footer {
	height: 84px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 calc(var(--padding) * 1.5);
	border-top: 1px solid var(--color-input-border-light);
	&__btn-wrapper {
		width: 160px;
		.button__block {
			&:hover {
				background: #2347a9;
				transition: all 0.2s ease-in-out;
				box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.Modal {
		width: 100%;
	}
	.sign-stepper__body {
		// padding-bottom: 114px;
		.esign-generate-sign__wrapper{
			padding-bottom: 10px;
		}
		.draw-sign, .upload-sign__wrapper {
			padding-bottom: 16px;
		}
		.draw-sign .draw-sign__sign-pad{
			min-height: unset;
		}
	}
	.create-sign {
		overflow-y: auto;
		padding: unset;
		height: calc(100% - 290px) !important;
		&__step-wrapper {
			// height: auto !important;
			padding: 8px;
		}
		&__label{
			padding: 8px;
		}
		&__name{
			padding: 8px;
		}
		&__consent {
			padding: 8px;
			position: fixed;
			bottom: 84px;
			background: var(--color-bg-100-light);
			width: 100%;
			.checkbox-storybook{
				align-items: baseline;
			}
			.checkbox-storybook__label--text{
				white-space: normal;
			}
			.checkbox-storybook__field {
				height: 12px;
			}
		}
		&__consent-description{
			white-space: break-spaces;
			text-align: justify;
		}
	}

	.create-sign-modal {
		margin: unset !important;
		margin-top: auto !important;
		padding: 0px !important;
		.esign-Modal__wrapper {
			width: 100%;
			height: 100vh;
			overflow-y: auto;
			border-radius: unset;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
		}
	}
	.create-sign-header {
		padding: 0 calc(var(--padding) * 0.75);
	}
	.create-sign-footer {
		padding: calc(var(--padding) * 0.75);
		position: fixed;
		bottom: 0;
		background: var(--color-bg-100-light);
		width: 100%;
	}
	.create-sign-footer__btn-wrapper {
		width: 100%;
	}

	.esign-generate-sign__wrapper {
		min-width: 366px !important;
		height: auto !important;
	}


	.esign-generate-sign__container {
		grid-template-columns: repeat(1, 1fr) !important;
		max-width: 362px !important;
		margin: auto;
	}
	.upload-sign {
		&__sign-box {
			min-height: 200px;
			height: 100%;
			width: 100%;
		}
	}
}

// generate css start from here please TODO @ankur

.esign-generate-sign {
	&__heading {
		font-weight: 500;
		font-size: var(--font-size-3);
		line-height: var(--line-height-5);
		color: var(--color-text-60-light);
	}

	&__wrapper {
		height: 176px;
		overflow-y: auto;
		max-width: 700px !important;
		gap: calc(var(--margin) * 0.5) var(--margin);
	}

	&__container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: calc(var(--margin) * 0.5) var(--margin);
		max-width: 686px;
		@media screen and (max-width: 606px) {
			grid-template-columns: repeat(1, 1fr) !important;
        	max-width: 362px !important;
        	margin: auto;
		}
	}

	&__sign-samples {
		position: relative;
		height: 80px;
		padding: 20px;
    	padding-top: 30px;
		display: flex;
		align-items: center;
		background-color: var(--color-input-background);
		cursor: pointer;
		border: 1px solid var(--color-input-border-light);
		border-radius: calc(var(--border-radius) * 0.25);
		transition: 0.2s ease-in all;
		&:hover,
		&--active {
			border: 1px solid var(--color-esign-primary-light);
		}
		&__name-box {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			padding: 0px;
			width: 100%;
		}
	}
	&__checkbox {
		position: absolute;
		top: 6px;
		left: 6px;
	}

	&__full_name {
		font-style: normal;
		font-weight: 500;
		font-size: var(--font-size-4);
		line-height: var(--line-height-4);
		position: relative;
		width: fit-content;
		padding-left: calc(var(--padding) * 0.25);
		display: flex;
		align-items: center;
		height: 42px;
		span {
			position: absolute;
			bottom: 28px;
			left: 12px;
			img {
				width: 36px;
			}
		}
		&:before {
			content: '';
			position: absolute;
			width: 12px;
			border-top: 1px solid var(--color-esign-primary-light);
			border-bottom: 1px solid var(--color-esign-primary-light);
			border-left: 1px solid var(--color-esign-primary-light);
			top: -6px;
			bottom: -6px;
			left: 0;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
			height: inherit;
		}
	}

	&__initials {
		font-style: normal;
		font-weight: 500;
		font-size: var(--font-size-4);
		line-height: var(--line-height-4);
		position: relative;
		width: fit-content;
		padding-left: calc(var(--padding) * 0.25);
		display: flex;
		align-items: center;
		justify-self: center;
		height: 42px;
		span {
			position: absolute;
			bottom: 28px;
			left: 12px;
			img {
				width: 36px;
			}
		}
		&:before {
			content: '';
			position: absolute;
			width: 12px;
			border-top: 1px solid var(--color-esign-primary-light);
			border-bottom: 1px solid var(--color-esign-primary-light);
			border-left: 1px solid var(--color-esign-primary-light);
			top: -6px;
			bottom: -6px;
			left: 0;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
			height: inherit;
		}
	}

	&__scroll-btn {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 500;
		font-size: var(--font-size-3);
		line-height: var(--line-height-5);
		color: var(--color-esign-primary-light);
		cursor: pointer;
		text-align: end;
	}
}
