.config-text-input {
  .input__text-field:hover{
    border-color: var(--color-esign-primary-light);
  }
  .input__label {
    color: var(--color-text-90-light);
  }
  .input__group{
    height: 32px !important;
  }
}
