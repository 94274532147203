.signing-screen {
  &__header {
    width: 100%;
    //  height: 96px;
  }
  &__header-banner {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-esign-primary-light);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: var(--line-height-6);
    color: var(--color-bg-100-light);
  }
  &__control-panel {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(var(--margin) * 0.5);
    background-color: var(--color-bg-ligh);
    border: 1px solid #d9e0f2;
    padding: calc(var(--padding) * 0.5) var(--padding);
  }
}
@media (max-width: 1024px) and (orientation: portrait),
       (max-width: 1024px) and (orientation: landscape)  {
  .signing-screen {
    &__control-panel {
      display: none;
    }
  }
}

.warning-text-wrapper p {
  background-color: var(--color-esign-primary-light);
  color: var(--color-bg-100-light);
  font-size: var(--font-size-4);
  padding: calc(var(--padding) * 0.5);
  text-align: center;
}

.toolkit-wrapper {
  background-color: var(--color-bg-light);
  padding-top: calc(var(--padding) * 0.5);
  padding-bottom: calc(var(--padding) * 0.5);
  text-align: center;
  display: flex;
  justify-content: center;
  gap: var(--margin);
}

.toolkit_icon {
  background: var(--color-bg-100-light) !important;
  font-size: var(--font-size-6);
  width: 56px !important;
  border-radius: calc(var(--margin) * 0.25);
  display: grid;
  place-items: center;
  &:hover {
    color: var(--color-blue);
    transition: all 0.2s ease-in-out;
    background: var(--color-input-border-light) !important;
  }
}
@mixin zoom-font {
  font-size: var(--font-size-5);
  font-weight: 600;
  height: 34px;
}
#zoom-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  #zoom-percent {
    @include zoom-font();
    font-weight: 400;
    width: 63px;
    text-align: right;
    border-style: initial;
    padding-right: calc(var(--padding) * 1.5);
    background: #ffffff
  }
  > span {
    @include zoom-font();
    position: relative;
    left: -22px;
    font-size: var(--font-size-5);
    height: 28px;
  }
}
.pdf-signer-container__content-wrapper {
  width: auto;
  min-width: fit-content;
}

.zoom-reset-button {
  display: unset;
  padding: 8px 13px;
  border-radius: 5px;
  border: solid 1px var(--color-esign-primary-light);
  color: var(--color-esign-primary-light);
}
