.browse-file-sign-doc {
  &__loader-text {
    font-weight: 500;
    font-size: var(--font-size-4);
    color: var(--color-text-90-light);
  }
  .browse-file {
    &__label-box {
      width: 100%;
      margin: calc(var(--margin) * 0.5) 0;
      min-height: 220px;
      background-color: var(--color-bg-90-light);
      border: 1px dashed var(--color-border-90-light);
      border-radius: calc(var(--border-radius) * 0.5);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      &:hover {
        border: 1px solid var(--color-esign-primary-light);
        transition: 0.2s ease-in all;
      }
    }
    &__logo {
      font-size: 68px;
      color: var(--color-esign-primary-light);
    }
    &__label-text {
      display: flex;
      gap: calc(var(--margin) * 0.25);
      align-items: center;
    }
    &__light-text {
      font-size: var(--font-size-3);
      color: var(--color-text-80-light);
      font-weight: 500;
    }
    &__click-label {
      color: var(--color-esign-primary-light);
    }

    &__description {
      color: var(--color-text-50-light);
      font-size: var(--font-size-2);
      font-weight: 400;
    }

    &__input {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: transparent;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.upload-documents {
  max-width: 800px;
  margin: 0 auto;
  margin-top: calc(var(--margin) * 0.5) 0 0 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-text-white);
  
  &__header {
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-4);
    line-height: var(--line-height-6);
    color: var(--color-text-90-light);
  }
}

.uploadedFiles__name {
  cursor: pointer;
}

.upload-doc-table {
  &__action-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  &__action-wrapper-swap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }
  &__swap-doc-wrapper {
    width: 96px;
  }
  &__swap-doc {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-esign-primary-light);
    cursor: pointer;
    background: rgba(60, 101, 214, 0.06);
    border-radius: 32px;
  }
  &__delete-button {
    font-size: 16px;
    padding: 4px 8px;
    border-radius: 32px;
  }

}