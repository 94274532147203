.pdf-signer-container {
	max-height: calc(100vh - 176px);
	width: 100%;
	position: relative;
	overflow-y: auto;
	display: flex;
    justify-content: center;
	&__placeholder {
		font-size: var(--font-size-5);
		line-height: var(--line-height-5);
		padding-top: 30vh;
		text-transform: capitalize;
	}
	&__signing-node {
		cursor: pointer;
		border-radius: calc(var(--margin) * 0.25);
		border: none;
		outline: none;
	}

	&__image-wrapper {
		position: relative;
		overflow: clip;
		.pdf-signer-container__pdf-img {
			width: 100%;
			height: 100%;
		}
	}
	&__doc {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
	&__pdf-img {
		width: 100%;
		border: 1px solid var(--color-input-border-light);
	}
	&__page-details {
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		font-weight: 500;
	}
	&__content-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc((var(--margin) * 0.25));
	}
	&__doc-description {
		font-size: var(--font-size-1);
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		font-weight: 500;
		padding: 0 2px;
	}
	&__tooltip {
		z-index: 1000;
		color: var(--color-bg-100-light);
		font-size: var(--font-size-2) !important;
		padding: calc(var(--padding) * 0.25) calc(var(--padding) * 0.5) !important;
		transform: none !important;
		scale: unset !important;
	}
	&__tooltip-length {
		max-width: 500px !important;
	}
	&__optional-flag-signature {
		position: absolute;
		top: 47px;
		width: 100%;
		margin: auto;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		border: 1px solid #000;
		font-size: 6px;
		padding: 0 2px;
	}
	&__optional-flag {
		margin: auto;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		border: 1px solid #000;
		font-size: var(--font-size-3);
		padding: 0 2px;
	}
}

.signing-node {
	display: flex;
	justify-content: center;
	align-items: center;
	font-style: normal;
	border: 1px solid #000;
	font-size: 8px;
	&__sign-img-container {
		margin-left: calc(var(--margin) * 0.25);
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	&__selected-node {
		border: 2px solid var(--color-esign-primary-light) !important;
		&__optional-flag {
			top: 49px !important;
		}
	}
	&__selected-checkbox {
		outline: 1px solid var(--color-esign-primary-light) !important;
	}
	&__date {
		border: none;
		cursor: pointer;
	}
	&__date-field {
		border: 1px solid var(--color-esign-primary-light);
		cursor: pointer;
	}
	&__email {
		border: none;
	}
	&__initial {
		flex-direction: column;
		min-width: fit-content;
		position: relative;
	}
	&__link {
		flex-direction: column;
		text-decoration: none;
		cursor: pointer;
	}
	&__name {
		border: none;
	}
	&__signature {
		flex-direction: column;
		position: relative;
	}
	&__image-Text {
		position: relative;
		z-index: 1;
		padding-left: 0;
		margin: calc(var(--margin) * 0.25) 0 0 calc(var(--margin) * 0.5);
		display: flex;
		.simplici-logo {
			position: absolute;
			bottom: 24px;
			left: 13px;
		}
		.init-text {
			position: absolute;
			bottom: 30px;
			left: 6px;
			&__id {
				position: absolute;
				font-size: 12px;
				top: 42px;
				left: 8px;
			}
		}
		.id {
			position: absolute;
			font-size: 8px;
			top: 33px;
			left: 13px;
		}
		&:before {
			content: '';
			position: absolute;
			border-top: 1px solid var(--color-esign-primary-light);
			border-bottom: 1px solid var(--color-esign-primary-light);
			border-left: 1px solid var(--color-esign-primary-light);
			top: 0;
			bottom: 0;
			left: 0;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
		}
	}
	&__text {
		word-break: break-all;
		// &--address {
		// 	// max-width: 240px;
		// }
		// &--name {
		// 	// max-width: 200px;
		// }
		// &--email {
		// 	// max-width: 220px;
		// }
	}

	&__checkbox {
		// Styles for the checkbox input
		input {
			border: 2px solid var(--color-input-border-light); // Sets the border with a light color variable
			cursor: pointer; // Changes cursor to pointer for better UX
			border-radius: 0px; // Ensures sharp corners on the checkbox
		}
	}
	&__radiogroup {
		// Styles for the checkbox input
		input {
			border: 2px solid var(--color-input-border-light); // Sets the border with a light color variable
			cursor: pointer; // Changes cursor to pointer for better UX
			border-radius: 0px; // Ensures sharp corners on the checkbox
			min-width: unset !important;
			min-height: unset !important;
			scale: unset !important;
			transform: unset !important;
		}
		
		input[type="radio"]:disabled {
			-webkit-appearance: none;
			padding: 0px;
			background-clip: content-box;
			border: 2px solid grey;
			background-color: transparent;
			border-radius: 50%;
			position: absolute;
    		display: inline-block;
		}


		input[type="radio"]:disabled:checked {
			-webkit-appearance: none;
			padding: 1px;
			background-clip: content-box;
			border: 2px solid grey;
			background-color: grey;
			border-radius: 50%;
			position: absolute;
    		display: inline-block;
		}
	}

	&__checkbox-safari {
		// Custom styles for checkboxes in Safari
		input[type='checkbox'] {
		  // Reset the default checkbox appearance
		  appearance: none;
		  -webkit-appearance: none; // For Safari to use custom appearance
	  
		  // Basic box styling
		  display: inline-block;
		  border: 1px solid #767676;
		  background-color: white;
		  border-radius: 2px;
	  
		  // Position and interaction styles
		  position: relative;
		  cursor: pointer;
	  
		  // Smooth transition effects for background and border changes
		  transition: background-color 0.1s ease, border-color 0.1s ease;
		}
	  
		// Styles applied when the checkbox is checked
		input[type='checkbox']:checked {
		  background-color: #0051cc;
		  border-color: #0051cc;
		}
	  
		// Checkmark style for checked state using pseudo-element
		input[type='checkbox']:checked:before {
		  // Empty content to represent the checkmark
		  content: '';
	  
		  // Positioning inside the checkbox
		  position: absolute;
		  left: 50%; // Horizontally center the checkmark
		  top: 40%;  // Vertically adjust the checkmark
	  
		  // Dimensions of the checkmark
		  width: 20%;
		  height: 50%;
	  
		  // Creating the checkmark shape using borders
		  border: solid white;
		  border-width: 0 2px 2px 0;
	  
		  // Aligning and rotating to form the checkmark
		  transform: translate(-50%, -40%) rotate(45deg);
		}
	}
	
	&__text-input-node {
		outline: none;
		border: 1px solid var(--color-esign-primary-light);
		white-space: nowrap;
		display: flex;
		align-items: center;
		padding: 0 2px;
		overflow: scroll;
		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
		}
	}
	&__read-only {
		background-color: transparent !important;
		border: none !important;
		padding: 0px !important;
		cursor: default !important;
		justify-content: unset;
		align-items: unset;
	}
	&__initial-img {
		padding: unset;
		border: unset;
		background: unset;
	}
	&__signature-img {
		padding: unset;
		border: unset;
		background: unset;
	}
}

.signing-text-node {
	resize: none;
	border: 1px solid var(--color-esign-primary-light);
	overflow: hidden;
	padding: 2px;
	&:active {
		border-radius: unset !important;
	}
	&::-webkit-scrollbar {
		width: 0px;
	}
}
.date-input::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}
