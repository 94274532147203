  .editable-header {
    &__text-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
    &__text-wrapper-read {
      display: flex;
      align-items: center;
      gap: 8px;
      pointer-events: none;
    }
    &__text {
      width: 100%;
      height: 30px;
      font-style: normal;
      font-weight: 600;
      font-size: var(--font-size-4);
      line-height: var(--line-height-4);
      color: var(--color-text-90-light);
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 2px;
    }
    &__edit-icon {
      margin-left: calc(var(--margin) * 0.5);
      height: auto;
      font-size: 20px;
    }
    &__input-wrapper {
      position: relative;
      width: 250px;
      height: 30px;
    }
    &__input {
      width: 100%;
      border: 1px solid var(--color-esign-primary-light);
      border-radius: calc((var(--border-radius) * 0.25));
      padding: calc((var(--padding) * 0.25));
      outline: none;
      font-size: 16px;
      font-weight: 600;
      &--error {
        border-color: var(--color-error);
      }
    }
    &__submit-wrapper {
      position: absolute;
      width: 30px;
      z-index: 1;
      margin-left: 4px;
      background-color: #fff;
      top: 0px;
      bottom: 2px;
      left: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      cursor: pointer;
      transition: 0.3ms all ease-in-out;
      &:hover {
        .editable-header__submit-icon {
          color: var(--color-success);        
        }
      }
    }
    &__submit-icon {
      font-size: 20px;
      font-weight: 600;
    }

    &__iserror {
      color: var(--color-error);
      font-size: var(--font-size-2);
      font-weight: 500;
    }
  }  