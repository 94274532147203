.number-input {
  display: flex;
  align-items: center;
  gap: calc(var(--margin) * 0.5);
  .input {
    width: 80px !important;
  }
  .input__group {
    width: 80px !important;
    height: 32px !important;
  }
  .input__text-field {
    &:hover {
      border: 1px solid var(--color-esign-primary-light);
      transition: all 0.2s ease-in-out;
      box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
    }
  }

  &__label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    color: var(--color-text-80-light);
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 480px) {
  .number-input__label {
    white-space: unset;
  }
}
