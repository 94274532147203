.esign-toggle-switch {
    display: flex;
    gap: calc(var(--margin) * 0.5);
    align-items: center;
  &__label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    color: var(--color-text-80-light);
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .react-switch-bg{
    height: 20px !important;
    width: 44px !important;
  }
  .react-switch-handle{
    height: 12px !important;
    width: 12px !important;
  }  
}

