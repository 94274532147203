.signing-order {
  &__body {
    max-height: 436px;
    overflow-y: auto;
  }
  &__header {
    font-size: var(--font-size-5);
    font-weight: 500;
    padding: var(--padding);
    border-bottom: 1px solid var(--color-input-border-light);
    font-size: var(--font-size-4);
    line-height: var(--line-height-6);
    color: var(--color-text-90-light);
    border-bottom: 1px solid var(--color-input-border-light);
  }
  &__body-sender {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-4);
    line-height: var(--line-height-6);
    color: var(--color-text-60-light);
  }
  &__body-data {
    display: flex;
    justify-content: space-between;
    padding: var(--padding);
    border-bottom: 1px solid var(--color-input-border-light);
  }

  &__completed-badge {
    display: flex;
    align-items: center;
    padding: var(--padding);
  }
  &__render-data {
    max-height: 260px;
    overflow-y: auto;
  }
  &__badgeData {
    display: flex;
    justify-content: space-between;
    padding: calc(var(--padding));
    border-bottom: 1px solid var(--color-input-border-light);
  }
  &__index {
    padding-right: calc(var(--padding) * 2);
  }
  &__initials {
    display: flex;
    gap: calc(var(--margin) * 0.5);
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
  &__badge {
    background: rgba(139, 69, 245, 0.12);
    color: var(--color-purple);
    display: grid;
    align-items: center;
    font-size: var(--font-size-2);
    text-align: center;
    border-radius: calc(var(--margin) * 1.25);
    min-width: 40px;
    min-height: 40px;
    font-weight: 600;
    cursor: pointer;
    padding: calc(var(--padding) * 0.5);
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: var(--padding);
    gap: calc(var(--margin) * 0.5);
    border-top: 1px solid var(--color-input-border-light);
    .button__filled--primary {
      &:hover {
        background: #2347a9;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
      }
    }
  }
}
.ri-3x {
  color: var(--color-green);
}
