.private-message-text-box-container {
  .input__text-field {
    padding: 0;
  }
  textarea {
    padding: calc(var(--padding) * 0.25);
    background: rgba(60, 101, 214, 0.06);
    border: 1px solid #d7dff5;
    border-radius: calc(var(--border-radius) * 0.25);
    font-style: normal;
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

.prepare-dashboatrd {
  &__cancel-btn, &__continue-btn {
    width: 108px;
    height: 52px;
  }
}