  .esign-modal-main {
    cursor: default;
    position: fixed;
    left: 0;
    // top: 0;
    right: 0;
    // bottom: 0;
    background: fade-in($color: transparent, $amount: 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    .esign-modal-content {
      max-width: 480px;
      background-color: var(--color-bg-100-light);
      margin-bottom: 150px;
      border-radius: calc(var(--border-radius) * 0.75);
      text-align: center;
      .esign-modal-title {
        margin: 12px;
        font-weight: 700;
        font-size: var(--font-size-4);
        line-height: var(--line-height-4);
      }
      .esign-modal-footer {
        display: flex;
        gap: calc(var(--margin) * 0.5);
        justify-content: flex-end;
        padding: calc(var(--padding) * 0.5);
      }
    }
  }
  .esign-modal-des {
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    padding: calc(var(--padding) * 1.5) calc(var(--padding) * 2);
    overflow-wrap: anywhere !important;
    white-space: normal !important;
    text-align: center !important;
  }
  .esign-confirmation-header {
    height: 160px;
    border-bottom: 1px solid var(--color-white-border);
    padding: var(--padding) calc(var(--padding) * 0.5);
    background-color: var(--color-bg-modal);
    border-top-left-radius: calc(var(--padding) * 0.75);
    border-top-right-radius: calc(var(--padding) * 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .esign-confirmation-image {
    width: 96px;
    height: 96px;
  }  