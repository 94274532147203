.verification {
  &__success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 75vh;
    align-items: center;
    //gap: calc(var(--margin) * 1.5);
  }
  &__success-message {
    font-size: var(--font-size-7);
    font-weight: 500;
    text-align: center;
    line-height: var(--line-height-7);
    margin-bottom: var(--margin);
  }
  &__success-description {
    font-size: var(--font-size-3);
    font-weight: 500;
    text-align: center;
    line-height: var(--line-height-3);
  }
}
