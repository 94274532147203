  .esign-overlay {
    background-color: hsla(0, 0%, 0%, 0.3);
    &--show {
      display: block;
    }
  }  
  .esign-drawer {
    height: fit-content;
    background-color: var(--color-bg-100-light);
    position: fixed;
    top: 78px;
    bottom: 78px;
    z-index: 999999;
    right: -300px;
    transition: 240ms ease-in-out;
    width: 283px;
    &--open {
      right: 0px;
    }
    &__close-btn {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: var(--font-size-6);
      }
    }
    &__body {
      height: calc(100vh - 156px);
      border-top: 1px solid var(--color-border-90-light);
      border-bottom: 1px solid var(--color-border-90-light);    
    }
  }  