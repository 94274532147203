  .text-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(var(--margin) * 0.25);
    font-weight: 600;
    &__label {
      font-size: var(--font-size-3);
      line-height: var(--line-height-3);
      color: var(--color-input-border-focus-dark);
      text-transform: capitalize;
    }
    &__input-field {
      width: 100%;
      resize: none;
      display: flex;
      align-items: center;
      background: var(--color-input-background);
      border: 1px solid var(--color-input-border-light);
      border-radius: calc(var(--border-radius) * 0.5);
      padding: var(--padding);
      font-size: var(--font-size-4);
      line-height: var(--line-height-4);
      outline: none;
      font-family: poppins;
      &:focus {
        border: 1px solid var(--color-esign-primary-light);
        transition: 0.2s ease-in all;
      }
    }
    &__input-field::placeholder {
      color: #ccc;
    }
  }
  .text-area-field {
    resize: none;
  }