.sign-order-checkbox {
	&-container {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		height: 20px;
		margin: 1rem 0;
		display: flex;
		column-gap: 0.5rem;
	}
}
.add-recipient {
	width: 850px;
	.esign-Modal__wrapper {
		padding: unset;
		border-radius: calc(var(--border-radius) * 0.5);
		background-color: #fff;
	}
	.private-message-button {
		&-active {
			color: #3c65d6;
		}
		&-inactive {
			opacity: 0.7;
		}
	}
	&__header {
		position: sticky;
		top: 0;
		background-color: var(--color-white);
		font-size: var(--font-size-5);
		font-weight: 500;
		// padding: calc(var(--padding) * 0.5) var(--padding);
		border-bottom: 1px solid var(--color-input-border-light);
		font-size: var(--font-size-4);
		line-height: var(--line-height-6);
		color: var(--color-text-90-light);
		width: 100%;
		margin-top: 12px;
	}
	&__heading{
		max-width: 800px;
		margin: 0 auto;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: var(--padding);
		gap: calc(var(--margin) * 0.5);
		border-top: 1px solid var(--color-input-border-light);
		position: sticky;
		bottom: 0;
		background-color: var(--color-white);
		max-width: 800px;
		width: 100%;
		margin: 0 auto;

		.button__filled--primary {
			&:hover {
				background: #2347a9;
				transition: all 0.2s ease-in-out;
				box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
			}
		}
		.button__filled--secondary {
			&:hover {
				background: var(--color-light-grey);
				transition: all 0.2s ease-in-out;
				box-shadow: 0 2px 8px rgb(40 46 66 / 20%);
			}
		}
	}
	&__btn-container {
		display: flex;
		gap: 16px;
	}

	&__body {
		padding: calc(var(--padding) * 0.5) 0px;
		display: flex;
		flex-direction: column;
		gap: calc(var(--margin) * 0.5);
		max-width: 800px;
		width: 100%;
		margin: 0 auto;
	}
	&__form-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__form-header-text {
		font-style: normal;
		font-weight: 600;
		font-size: var(--font-size-4);
		line-height: var(--line-height-6);
		color: var(--color-text-90-light);
		display: none;
	}
	&__add-btn {
		display: flex;
		align-items: center;
		padding: calc(var(--padding) * 0.75) calc(var(--padding) * 1.5);
		gap: calc(var(--margin) * 0.5);
		background: rgba(60, 101, 214, 0.06);
		border-radius: calc(var(--border-radius) * 0.5);
		border: 1px solid var(--color-esign-primary-light);
		// border-radius: calc(var(--margin) * 2);
		cursor: pointer;
		span {
			font-style: normal;
			font-weight: 500;
			font-size: var(--font-size-2);
			line-height: var(--line-height-4);
			color: var(--color-esign-primary-light);
		}
		i {
			color: var(--color-esign-primary-light);
			font-size: var(--font-size-4);
		}
	}

	&__form {
		&-container {
			display: flex;
			align-items: center;
			column-gap: 1rem;
			width: 100%;
			z-index: inherit !important;
		}
		width: 50rem;
		display: flex;
		flex-direction: column;

		border: 1px solid #d7dff5;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		border-width: 1px 1px 1px 0px;
		border-style: solid;
		border-color: #d7dff5;
		min-height: fit-content;
		background-color: var(--color-white);
		.private-message-button {
			&-active {
				color: #3c65d6;
			}
			&-inactive {
				opacity: 0.5;
			}
		}
	}
	&__handle-container {
		display: grid;
		align-items: center;
		grid-template-columns: 1rem 1fr;
		column-gap: 1rem;
		background-color: white;
		.options {
			&-container {
				display: flex;
				align-items: center;
			}
			&-label {
				margin-left: 5px;
			}
		}
	}
	&__card-color {
		border-left: 6px solid;
		padding: calc(var(--padding) * 1.25);
		padding-left: var(--padding);
	}
	&__controls-container {
		display: flex;
		row-gap: 4px;
		flex-direction: column;
	}
	&__form-actions-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: calc(var(--margin) * 0.75);
	}
	&__remove-recipient {
		&:hover {
			background-color: #ffebeb;
			transition: all 0.2s ease-in-out;
			box-shadow: 0 1px 4px rgb(40 46 66 / 20%);
		}
	}

	&__remove-recipient {
		font-size: var(--font-size-5);
		color: var(--color-danger-dark);
		cursor: pointer;
	}

	&__form-fields {
		display: flex;
		align-items: center;
		gap: 16px;
		&--prefilled {
			display: flex;
			flex-direction: column;
			margin: 4px 0;
		}
		label,
		&--label {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			color: #747e99;
		}
		&--value {
			font-size: 14px;
			font-weight: 500;
			color: #000;
			max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
		}

		.input__label {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			color: #747e99;
		}
		.input__text-field {
			height: 2.25rem;
		}
	}
	&__row-2 {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: calc(var(--margin) * 0.75);

		font-size: 14px;
	}
	&__recipient-list {
		display: flex;
		flex-direction: column;
		gap: calc(var(--margin) * 0.5);
		max-height: 20rem;

		overflow-y: auto;
		&_number {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 16px;
			width: 36px;
			height: 36px;
			background: rgba(60, 101, 214, 0.06);
			color: #8c97b8;
			border: 1px solid #d7dff5;
			border-radius: 6px;
			font-size: 14px;
		}
		&_draggable {
			display: flex;
			align-items: center;
			flex-direction: column;
			background-color: #fff;
		}
	}
	&__dropContainer {
		gap: 0.5rem;
		display: flex;
		flex-direction: column;
		height: inherit;
		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	&__original-copy {
		&__container {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
			padding-right: 16px;
		}

		&__radio-btn {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			outline: none;
			border: 2px solid var(--color-border-90-light);
			cursor: pointer;
		}

		&__title {
			color: var(--color-text-90-light);
			font-size: var(--font-size-3);
			font-style: normal;
			font-weight: 600;
			line-height: var(--line-height-3);
		}
	}
}

.add-recipient-modal {
	padding: 16px;
}
.signing-order-view {
	font-size: 12px;
	font-weight: 600;
	cursor: pointer;
	color: #3c65d6;
}
.private-message-header {
	&-icon {
		margin-right: 0.5rem;
	}
}

.checkbox-storybook__label--text {
	width: 100%;
}

input[type='radio'] {
	accent-color: var(--color-esign-primary-light);
}
