.esign-react-date-picker {
	width: 100%;
	height: 100%;
	display: flex;
	outline: none;
	border: none;
}
.error-message {
	color: var(--color-error);
	font-size: var(--font-size-2);
}
.active-error-message {
	border-color: var(--color-error);
	background-color: rgba(255, 0, 0, 0.06);
}

.date-calender-overlay,
.Calender {
	top: unset;
}
.date-calender-center-overlay {
	z-index: 99999999 !important;
	.Calendar__yearText,
	.Calendar__monthText {
		border: 1px solid var(--color-input-border-light);
	}
	.Calendar__yearText::after,
	.Calendar__monthText::after {
		content: '>';
		transform: rotate(90deg);
		margin-left: 6px;
	}
}
.date-calender-overlay {
	position: absolute;
	&--top-right {
	  left: 0;
	  bottom: 0;	
	}
	&--top-left {
	  bottom: 0;
	  right: 0;
	}
	&--bottom-right {
	  top: 0;
	  left: 0;
	}
	&--bottom-left {
	  top: 0;
	  right: 0;
	}
	&--center-top {
	  left: 50%;
	  bottom: 100%;
	  transform: translateX(-50%);
	}
	&--center-bottom {
	  left: 50%;
	  top: 100%;
	  transform: translateX(-50%);
	}
	&--center-left {
	  right: 100%;
	  top: 50%;
	  transform: translateY(-50%);
	}
	&--center-right {
	  left: 100%;
	  top: 50%;
	  transform: translateY(-50%);
	}
  }
.date-calender-center-overlay {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
