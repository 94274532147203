  .checkbox-storybook {
    display: flex;
    flex-direction: row-reverse;
    gap: calc(var(--margin) * 0.5);
    align-items: center;
    &__label {
      font-style: normal;
      font-weight: 500;
      font-size: var(--font-size-3);
      line-height: var(--font-size-5);
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: calc(var(--margin) * 0.5);
      &--text {
        color: var(--color-text-60-light);
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: var(--font-size-4);
        line-height: var(--line-height-4);
        font-style: normal;
      }
      &--link {
        white-space: nowrap;        
      }
    }
    &__field {
      accent-color: var(--color-esign-primary-light);
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  
  @media screen and (max-width: 480px) {
    .checkbox-storybook__label--text {
      width: 92% !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }