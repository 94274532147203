.Esign-back-to-simplici {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__loader {
        width: 100px;
        height: 100px;
    }
    &__description {
        font-size: 20px;
    }
}