  .dropdown {
    width: 100%;
    &__custom-btn {
      cursor: pointer;
    }
  }
  .option {
    display: flex;
    gap: calc(var(--margin) * 0.25);
    .option-name {
      color: var(--color-text-100-dark);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .option-subname {
      color: var(--color-text-100-dark);
    }
  }
  
  .css-qw1pih-control {
    min-width: 100px !important;
  }  