.color-picker {
  display: grid;
  gap: calc(var(--margin) * 0.25);
  position: relative;
  &__label {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    font-weight: 500;
  }
  &__input {
    height: 32px;
    min-width: 140px;
    border-radius: calc(var(--margin) * 0.25);
    border: 1px solid var(--color-input-border-light);
    display: flex;
    gap: var(--margin);
    padding: 0 calc(var(--padding) * 0.5);
    align-items: center;
    background: rgba(60, 101, 214, 0.06);
    cursor: pointer;
    &:hover {
      border-color: var(--color-esign-primary-light);
    }
  }
  &__active-color {
    width: 22px;
    height: 22px;
    border-radius: calc(var(--margin) * 0.25);
    border: 2px solid var(--color-input-border-light);
  }
  &__hex-code {
    text-transform: uppercase;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    color: var(--color-text-90-light);
  }
  &__modal {      
    z-index: 10;
    position: fixed;
    right: 288px;
    bottom: 82px;
  }
}
