  .prepare {
    &-container {
      height: 100vh;
      .add-recipient {
        &__signer {
          font-weight: 600; 
        }
        &__role {
          font-size: 14px;
          color: #000;
          font-weight: 500;
        }
        &-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          border-radius: 15px;
        }
        &__form-header-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &__form-header-toogle-wrapper {
          align-items: center;
          display: flex;
          gap: 10px;
          justify-content: flex-end;
          font-weight: 500;
          font-size: var(--font-size-3);
          line-height: 20px;
        }
        &__original-copy-wrapper {
          display: flex;
          flex-direction: column;
          gap: 12px__image-wrapper;
        }
        &__original-copy{
          &__toogle-wrapper {
          align-items: center;
          display: flex;
          gap: 10px;
          justify-content: space-between;
          font-weight: 600;
          span{
            font-size: var(--font-size-3);
            line-height: var(--line-height-3);
          }
        }
          &__toogle-description {
          color: var(--color-label-dark);
          font-size: var(--font-size-2);
          font-weight: 400;
          line-height: var(--line-height-2);
        }
      }
        &__recipient-list {
          min-height: 6rem;
          max-height: unset;
          overflow-y: unset;
        }
  
        &__no-recipient {
          max-height: 75vh;
          display: flex;
          justify-content: center;
          overflow: hidden;
        }
      }
    }
  }
  
  .prepare-dashboard__body {
    max-width: 800px;
    width: 100%;
    margin-top: 24px;
  }
  
  .add-recipient-data-container {
    height: calc(100vh - 80px - 57px);
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    padding: 0 16px;
  }
  .add-recipient-container {
    background-color: var(--color-bg-100-light);
    &__flags-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  .self-sign{
    &__image-wrapper{      
      text-align: center;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 100px;
        height: 100px;
      }
    }
    
    &__image-heading{
      font-weight: 600;
      font-size: var(--font-size-4);
      line-height: var(--line-height-4);
    }
    &__image-desc{
      font-weight: 400;
      font-size: var(--font-size-3);
      line-height: var(--line-height-3);
      color: var(--color-text-70-light);
    }
  }