  .Esign-signing-dashboard {
    &__loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;
    }
  }
  
  .signing-dashboard-wrapper {
    max-height: 100%;
  }
  
  .terms-condition {
    &__modal {
      width: 100%;
      z-index: 10000000;
    }
  }
  .positionRelative {
    position: relative;
  }
  
  .Esign-options-box {
    position: absolute;
    margin-top: calc(var(--margin) * 0.25);
    min-width: 148px;
    background: var(--color-bg-100-light);
    opacity: 22;
    border-radius: calc(var(--margin) * 0.25);
    min-height: 180;
    box-shadow: var(--color-input-border-light) 0px 8px 24px;
    border: 1px solid var(--color-input-border-light);
    display: flex;
    flex-direction: column;
    z-index: 99;
    &--default {
      top: 44%;
      left: 10%;
      margin-right: 30px;
    }

    &--top {
      bottom: 100%;
      right: 100%;
      margin-bottom: -12px;
      margin-left: -12px;
    }

    &--left {
      right: 100%;
      margin-top: -30px;
      margin-right: -20px;
    }

    &--topLeft {
      bottom: 100%;
      right: 100%;
    }

    &--topRight {
      left: 100%;
      bottom: 100%;
      margin-left: -20px;
      margin-bottom: -20px;
    }

    &__menu-item {
      font-size: var(--font-size-3);
      align-items: center;
      z-index: 1;
      font-weight: 500;
      line-height: var(--line-height-5);
      color: var(--color-text-70-light);
      width: 100%;
      border: none;
      background-color: unset;
      display: flex;
      align-items: center;
      gap: calc(var(--margin) * 0.25);
      height: 40px;
      white-space: nowrap;
		  margin-right: 6px;
    }
    &__menu-item:hover {
      background-color: var(--color-bg-90-light);
      border-radius: 2px;
      transition: all 0.3s;
      cursor: pointer;
    }
  }
  
  .space {
    margin-right: calc(var(--margin) * 0.5);
    font-weight: 500;
    font-size: var(--font-size-5);
    margin-left: calc(var(--margin) * 0.5);
    line-height: var(--line-height-5);
    color: var(--color-text-70-light);
  }
  
  .delete-icon {
    color: var(--color-delete);
  }

  @media screen and (max-width: 480px) {
    .signing-dashboard-wrapper {
      padding: unset;
      padding-bottom: 80px;
    }
    .pdf-signer-container {
      max-height: calc(100vh - 200px) !important;
    }
  }
  .esign-signing-screen {
    &__container{
      position: relative;
      height: 100vh;
    }
    &__tnc-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999999999;
    }
    &__dashboard-wrapper-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }