.preview-document {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  .collapsible__header{
    position: sticky;
    top: 0;
    background-color: var(--color-bg-100-light);
  }

  &__item {
    max-width: 196px;
    min-width: 196px;
    height: 282px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--color-input-border-light);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &.active {
      border: 2px solid var(--color-esign-primary-light);
    }
  }
  &__img {
    width: 100%;
    height: calc(100% - 32px);
    background-color: #fff;
  }
  &__layout-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-input-border-light);
    padding: calc(var(--padding) * 0.25) calc(var(--padding) * 0.5);
    height: 32px;
  }
  &__page-number {
    font-size: var(--font-size-2);
  }
  &__delete {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      font-size: var(--font-size-3);
      color: var(--color-error);
    }
    &:hover{
      background-color: #e5c1c1;
      font-weight: 600;
    }
  }
  &__placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 20vh;
    img {
      width: 140px;
      height: auto;
    }
    span {
      font-size: var(--font-size-2);
    }
  }
}

@media screen and (max-width: 860px) {
  .preview-document__item {
    min-width: 162px;
    max-height: 282px;
  }
}
