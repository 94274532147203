.verification {
  &__success {
    display: flex;
    flex-direction: column;
    height: 75vh;
    justify-content: center;
    align-items: center;
    //gap: calc(var(--margin) * 1.5);
  }
  &__success-message {
    font-size: var(--font-size-5);
    font-weight: 500;
    text-align: center;
    line-height: 28px;
  }
}
