.server-error {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 25vh;
  &__description {
    text-align: center;
  }
  &__message {
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    text-align: center;
    color: var(--color-text-80-light);
  }
}
