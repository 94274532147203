.editors-dashboard {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    overflow: hidden;
    height: 100vh;
    &__header {
      grid-column-start: span 12;
      border-bottom: 1px solid var(--color-border-90-light);
    }
    &__left_sidebar {
      min-width: 260px;
      border-right: 1px solid var(--color-border-90-light);
      overflow-y: auto;
      overflow-x: hidden;
    }
    &__main {
      grid-column-start: span 10;
      overflow: auto;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar:horizontal {
        height: 10px !important;
      }
    }
    &__main_readOnly {
      margin-bottom: 2rem;
      grid-column-start: span 11;
      overflow: auto;
      display: flex;
      flex-direction: column;
      height: 100%;       // paras : view doc scroll issue 
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      }
    }
    &__right_sidebar {
      overflow: auto;
      min-width: 284px;
      border-left: 1px solid var(--color-border-90-light);
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      }
    }
    &__right_sidebar_readOnly {
      margin-bottom: 2rem;
      overflow: auto;
      min-width: 284px;
      border-left: 1px solid var(--color-border-90-light);
      height: 100%; // paras : view doc scroll issue 
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      }
    }
    &__footer {
      grid-column-start: span 12;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid var(--color-border-90-light);
    }
  }
  
  .Esign-dashboard__loader-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media screen and (max-width: 860px) {
    .editors-dashboard {
      &__left_sidebar {
        min-width: 196px;
      }
      &__right_sidebar {
        min-width: 196px;
      }
    }
  }
  .custom-drag-item {
    padding: 0;
  }
  .custom-drag-item-outer {
    padding: 0;
  }  

  // paras : view doc scroll issue 
  .editors-dashboard_config {
    grid-template-rows: 79px auto 79px;
  }
  
  // paras : view doc scroll issue 
  .editors-dashboard_readonly {
    grid-template-rows: 79px auto 0px;
  }