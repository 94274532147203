.radiobuttonconfig {
  // width: 250px;
  // font-family: Arial, sans-serif;
  // border: 1px solid #ddd;
  // padding: 15px;
  border-radius: 8px;
  padding: 0 var(--padding);
  
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 10px;
    text-transform: capitalize;
  }
  
  .add-option {
    background-color: #e7f0ff;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 4px;
    border: 1px solid #007bff;
  }
  
  .options {
    display: flex;
    flex-direction: column;
  }
  
  .option {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-top: 6px;
  }
  
  .option input[type="radio"] {
    margin-right: 8px;
  }
  
  .option span {
    font-size: 14px;
  }

  .input__text-field:hover{
    border-color: var(--color-esign-primary-light);
  }
  .input__label {
    color: var(--color-text-90-light);
  }
  .input__group{
    height: 32px !important;
  }
 
 
  .radio-options,
  .appearance {
      // margin-bottom: 10px;

      &__heading {
          margin-bottom: 5px;
          color: var(--color-text-90-light);
          font-size: var(--font-size-3);
          display: block;
          text-transform: capitalize;
          font-weight: 500;
      }

      label {
          display: block;
          margin: 4px;
          font-size: 13px;
          cursor: pointer;
          display: flex;
          font-weight: 500;
          align-items: center;
          margin-bottom: 0px;
          input[type='radio'] {
              margin-right: 10px;
          }
      }
  }

  &__option-container{
      padding: 4px;
      border-radius: 4px;
      border: 1px solid #D9E0F2;
      background: #F3F6FD;
      margin-bottom: 10px;
  }

  .apply-setting {
      
      font-size: 13px !important;
      margin: 0px !important;
      margin-top: 10px !important;
      font-weight: 500;
      gap: 5px !important;
  }
 
}
