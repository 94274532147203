.Esign-camera-setting {
	padding: 24px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    &__permission-img{
        height: 180px;
        width: 180px;
    }
    &__permissions{
        font-weight: 600;
		font-size: 20px;
		line-height: 32px;
    }
}
