.edit-recipient {
  &_container {
    display: flex;
    column-gap: 8px;
    color: #6c707a;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    height: 50px;
    border-top: 1px solid #d7dff5;
    &:hover {
      i {
        color: #4a5060;
      }
      p {
        color: #373a3f;
      }
    }
  }
  &_icon {
    color: #747e99;
    font-size: 14px;
  }
  &_text {
    height: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6c707a;
    margin: unset;
    padding: unset;
  }
}
