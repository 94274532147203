.action-menu {
    &-primary-button {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #464c5c;
      background-color: transparent;
      border: 1px solid #d7dff5;
      border-radius: 8px;
      width: 169px;
      height: 40px;
    }
    &-options-container {
      position: absolute;
      border: 1px solid #d7dff5;
      border-radius: 8px;
      width: 169px;
      color: #464c5c;
      background-color: #fff;
      &:empty {
        display: none;
      }
      button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        height: 40px;
        width: 100%;
        background-color: transparent;
        border: 0px;
        border-bottom: 1px solid #c5c5c5;
        &:last-of-type {
          border: 0px;
        }
        &:hover {
          background-color: #d9e0f9c5;
        }
      }
    }
  }  