  .esign-accordion {
    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-top: 1px solid #d7dff5;
      border-left: 1px solid #d7dff5;
      // border-radius: 0px 0px 8px 8px;
    }
    &-header {
      padding: 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: none;
      background: transparent;
      &-content {
        width: 100%;
        display: flex;
        font-weight: 500;
        font-size: var(--font-size-3);
        line-height: var(--line-height-3);
        color: var(--color-text-60-light);
      }
      &-button-container {
        display: flex;
        button {
          height: auto;
        }
      }
      &-down-arrow {
        font-size: var(--font-size-6);
        cursor: pointer;
      }
    }
    &-body {
      width: 100%;
      padding: 0 var(--padding) var(--padding) var(--padding);
    }
  }