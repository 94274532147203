.autocomplete-container {
	position: relative;
	width: 100%;
	&--top {
		top: 100%;
		left: 0px;
	}
	&--bottom {
		bottom: 100%;
		left: 0px;
	}
	&__input {
		position: absolute;
		margin-top: 8px;
		display: flex;
		justify-content: center;
		line-height: 1.5;
		background: white;
		border: 1px solid var(--color-bg-90-light);
		border-radius: 8px;
		z-index: 9999;
	}
	&__box {
		min-width: 150px;
		max-height: 250px;
		overflow: auto;
		cursor: pointer;
		box-shadow: rgba(0, 0, 0, 0.16) 1px 2px 5px;
	}

	&__options {
		display: flex;
		padding: 8px 12px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 4px;
		&--data{
			display: flex;
			align-items: center;
			gap:12px;
		}
		&--name {
			display: flex;
			gap: 4px;
			color: var(--Color-Text-Light-90, #2f323d);
			font-size: 14px;
			font-weight: 500;
		}
		&--email{
			display: flex;
			align-items: center;
			gap: 4px;
			&__label {
				display: flex;
				gap: 4px;
				color: var(--Color-Text-Light-80, #464c5c);
				font-size: 12px;
				font-weight: 400;
			}
	

		}
		
		&:hover {
			background-color: var(--color-bg-90-light);
		}
	}
	&__options:not(:last-child) {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
}

.suggestions-list.above {
	top: auto;
	bottom: 100%;
}
.suggentions-loader{
	display: flex;
	flex-direction: column;
}