.link-expired {
	width: 100%;
	display: flex;
	justify-content: center;
	height: 100vh;
	align-items: center;
	&__description {
		text-align: center;
	}
	&__message-heading {
		font-style: normal;
		font-weight: 600;
		font-size: var(--font-size-4);
		line-height: var(--line-height-4);
		text-align: center;
		color: var(--color-text-80-light);
	}
	&__message-desc {
		font-style: normal;
		font-weight: 600;
		font-size: var(--font-size-3);
		line-height: var(--line-height-4);
		text-align: center;
		color: var(--color-text-110-light);
	}
}
