.self-sign {
	&__wrapper {
		width: 100vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		background-color: #fff;
	}
	&__header {
		width: 100%;
		height: 80px;
		border-bottom: 1px solid var(--color-border-90-light);
	}
	&__body {
		width: 100%;
		height: calc(100vh - 160px);
		display: flex;
	}
    &__footer-btn-wrapper {
        width: 140px;
    }
	&__footer {
		width: 100%;
		height: 80px;
		border-top: 1px solid var(--color-border-90-light);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 24px;
		gap: 16px;
	}
	&__left-sidebar {
		min-width: 240px;
		height: 100%;
		border-right: 1px solid var(--color-border-90-light);
	}
	&__config-area {
		height: 100%;
		width: 100%;
		overflow: auto;
	}
	&__preview-area {
		min-width: 284px;
		border-left: 1px solid var(--color-border-90-light);
        overflow: auto;
	}
	&__loader-wrapper {
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.fields-sidebar {
	&__wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		.menu-items {
			margin: 12px auto;
			height: calc(100% - 24px);
			width: 100%;
			&__heading, &__wrap {
				padding-left: 24px;
			}
			&__wrap {
				height: 100%;
			}
		}
	}
}

.self-sign-node {
	width: calc(100% - 4px);
	height: calc(100% - 4px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;	
	&__initial, &__signature {
		height: 100%;
		width: 100%;
		cursor: pointer;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: left;
		background-color: transparent;
	}
	input {
		outline: none;
		width: 100%;
		height: 100%;
	}
	textarea {
		width: 100%;
		height: 100%;
		resize: none;
		outline: none;
	}
	&__checkbox {
		width: 100%;
		height: 100%;
	}
	&__empty-sign {
		width: 100%;
		height: 100%;
		background-color: #bccbf4;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 8px;
	}
}