.simplici-logo {
    width: 200px;
    padding: 20px 20px;
}

.void-screen {
    width: 100%;
    max-width: 576px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10%;
    margin: auto;
    &__icon {
        font-size: 72px;
        color: var(--color-danger-dark);
    }
    &__details {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        gap: 16px;
    }

    &__title {
        color: #000;
        font-size: var(--font-size-5);
        font-style: normal;
        font-weight: 600;
        line-height: var(--line-height-5);
        text-align: center;
    }
    &__description {
        color: var( --color-text-70-light);
        text-align: center;
        font-size: var(--font-size-3);
        font-weight: 400;
        line-height: 20px;
    }
    &__support {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    &__disclaimer {
        color: var(--color-text-80-light);
        text-align: center;
        font-size: var(--font-size-3);
        font-weight: 500;
        line-height: var(--line-height-3);
    }
    &__footer{
        display: flex;
        padding: 12px 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--color-orange);
        background: var(--color-orange-light);
        &_icon{
            display: flex;
            width: 16px;
            height: 16px;
            justify-content: center;
            align-items: center;
            color: var(--color-orange);
        }
        &_text{
            color: var(--color-text-80-light);
            font-family: Poppins;
            font-size: var(--font-size-2);
        }
        &_text-note{
            font-weight: 700;
        }
    }
}