.config-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    color: var(--color-text-90-light);
    display: flex;
    align-items: center;
    padding: calc(var(--padding)) var(--padding);
    gap: calc(var(--margin) * 0.5);
  }
  &__wrap {
    overflow-y: auto;
    width: 100%;
  }
  &__header-back {
    display: none !important;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__body {
    width: calc(100% - 12px);
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 250px);
    position: relative;
    .collapsible {
      padding: 0 var(--padding);
    }

    .collapsible__header {
      background-color: unset;
      border: unset;
    }
    .collapsible__header:hover {
      border: unset;
    }
    .collapsible__children {
      align-items: unset;
      background-color: unset;
      padding-bottom: unset;
    }
  }
  &__footer {
    padding: calc(var(--padding)) var(--padding);
    .button__large {
      padding: calc(var(--padding) * 0.75) !important;
    }
    .esign-button__filled--danger {
      background-color: rgba(245, 83, 83, 0.12);
      color: red;
      &:hover {
        background-color: #ffebeb;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 1px 4px rgb(40 46 66 / 20%);
      }
    }
  }
  &__empty {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: var(--color-bg-90-light);
    border-left: 4px solid var(--color-purple);
  }
}

@media screen and (max-width: 860px) {
  .config-items {
    &__header {
      span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
