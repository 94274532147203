.recipient-tab {
    &__tabs {
        display: flex;
        align-items: center;
    }
	&__item {
		padding: 8px 16px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		border-radius: 4px 4px 0px 0px;
		background: rgba(0, 0, 0, 0.06);
		border: 2px solid transparent;
		border-bottom: unset;
        cursor: pointer;
		transition: all 0.5ms ease-in-out;		
		user-select: none;
        &--active {
            border-color: var(--color-esign-primary-light);
            background-color: transparent;            
            position: relative;
            ::after {
                content: "";
                height: 2px;
                width: 100%;
                position: absolute;
                left: 0px;
                top: 100%;
                background-color: #fff;

            }
        }
		&--disabled {
			opacity: 0.6;
			cursor: not-allowed;			
		}

	}
	&__item-title {
		color: #000;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
	&__item-description {
		color: var(--color-text-light-70, #5d657a);
		font-family: Poppins;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
	}
	&__children-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        padding-top: 16px;
	}
}